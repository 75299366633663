import { useRouter } from "next/router"

export default function storeUTMData() {
    const router = useRouter();
    const acceptableParams = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content", "utm_agid", "device", "placement", "gclid"];
    const isServer = typeof window === "undefined";
    for(const key in router.query) {
        if(acceptableParams.includes(key) && !isServer) {
            localStorage.setItem(key, router.query[key])
        }
    }
}