import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { scrollToElementById } from 'lib/scrollTo'

const normalizeText = (text: string): string => {
  return text
    .toLowerCase()
    .replace(/[^\w\s]/gi, '')
    .replace(/\s/g, '')
}

const scrollToHash = () => {
  const hash = window.location.hash.substring(1)
  if (!hash) return; // Skip if the hash is empty
  const normalizedHash = normalizeText(hash)
  const headings = document.querySelectorAll('h2, h3, h4')
  let headingMatch = false;
  headings.forEach((heading) => {
    const normalizedHeadingText = normalizeText(heading.textContent || '')
    if (normalizedHeadingText === normalizedHash) {
      const offsetTop = heading.getBoundingClientRect().top + window.pageYOffset - 100
      window.scrollTo({ top: offsetTop, behavior: 'smooth' })
      headingMatch = true;
    }
  })
  if(!headingMatch && hash.length){
    scrollToElementById(hash)
  }
}

export const anchorJumpLinks = (): void => {
  const router = useRouter()

  useEffect(() => {
    const timeout = setTimeout(() => {
      scrollToHash()
    }, 1000)

    const handleRouteChange = () => {
      scrollToHash()
    }

    router.events.on('hashChangeComplete', handleRouteChange)

    return () => {
      clearTimeout(timeout)
      router.events.off('hashChangeComplete', handleRouteChange)
    }
  }, [])
}
