import { useMemo } from 'react'
import { ApolloClient, HttpLink, ApolloLink, InMemoryCache } from '@apollo/client'
import { ErrorLink } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { concatPagination } from '@apollo/client/utilities'
import merge from 'deepmerge'
import { warnError } from '../utils/monitoring'

// Craft GraphQL types are generated by running `yay dev --schema` (see package.json and ./bin/dev)
import graphQlSchema from './generated/graphql-schema.json'

export const APOLLO_STATE_PROP_NAME = 'APOLLO_STATE'

let apolloClient

function createApolloClient(previewToken) {
  if (!process.env.NEXT_PUBLIC_API_TOKEN) {
    throw new Error(`Required environment variable is missing: NEXT_PUBLIC_API_TOKEN`)
  }
  if (!process.env.NEXT_PUBLIC_API_URL) {
    throw new Error(`Required environment variable is missing: NEXT_PUBLIC_API_URL`)
  }

  // Create headers object
  const bearerToken = process.env.NEXT_PUBLIC_API_TOKEN
  const headers = {
    Authorization: bearerToken ? `Bearer ${bearerToken}` : '',
  }

  // Define user agent header so that WAF firewall doesn't block API requests
  // from server-side requests (if not server-side, user agent will be included)
  // UA example: My Seller Tool/2.0 (Language=Java/1.6.0.11; Platform=Windows/XP)
  // Ref: https://docs.developer.amazonservices.com/en_UK/dev_guide/DG_UserAgentHeader.html
  if (typeof window === 'undefined') {
    headers['User-Agent'] = `ABC Homes/${new Date().getFullYear()}.${new Date().getMonth() + 1} Web/GraphQL` // used in WAF scope down exclusions
  }

  // Information about possibleTypes and Craft CMS GraphQL
  // https://craftcms.stackexchange.com/questions/37078/warning-heuristic-fragment-matching-going-on-when-querying-matrix-fields-or-an/41273#41273
  // https://www.apollographql.com/docs/react/data/fragments/#generating-possibletypes-automatically
  const possibleTypes = {}
  graphQlSchema.__schema.types.forEach((supertype) => {
    if (supertype.possibleTypes) {
      possibleTypes[supertype.name] = supertype.possibleTypes.map((subtype) => subtype.name)
    }
  })

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from([
      new RetryLink({
        delay: {
          initial: 500,
          max: 2000,
          jitter: true,
        },
        attempts: {
          max: 5,
          retryIf: (error, _operation) => !!error,
        },
      }),
      new ErrorLink(({ graphQLErrors, networkError, operation }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach((gqlErr) => {
            const { message, debugMessage } = gqlErr
            warnError(
              ...[
                `[GraphQL error]: Message: ${message}, Query: ${operation.query.definitions
                  .map((def) => def.name?.value)
                  .join('/')}, DebugMessage: ${debugMessage}`,
              ].filter(Boolean)
            )
          })
        }
        if (networkError) {
          warnError(`[GraphQL network error]: ${networkError}`)
        }
      }),
      new ApolloLink((operation, forward) => {
        // Add headers to context
        operation.setContext({ headers })

        // Add console logging
        const traceArray = new Error().stack?.split(/[\n\r]/)
        const filteredTrace = traceArray.filter((line) => line.includes('/pages/') || line.includes('/components/'))
        // console.log(`GQL op:${operation.operationName} vars:${JSON.stringify(operation.variables)} -${filteredTrace.join('') || ' no trace; on server?'}`)

        // Call next link in middleware chain
        return forward(operation)
      }),
      new HttpLink({
        uri: process.env.NEXT_PUBLIC_API_URL + previewToken,
        credentials: 'same-origin',
      }),
    ]),
    cache: new InMemoryCache({
      possibleTypes,
      typePolicies: {
        Query: {
          fields: {
            entry: {
              merge(existing = {}, incoming) {
                return { ...existing, ...incoming }
              },
            },
          },
        },
      },
    }),
  })
}

export function initializeApollo(previewToken, initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient(previewToken ? '?token=' + previewToken : '')

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache)

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export const mutateAPI = async (mutation, variables, previewToken) => {
  const _apolloClient = createApolloClient(previewToken ? '?token=' + previewToken : '')
  const { data } = await _apolloClient.mutate({
    variables: variables,
    mutation: mutation,
  })

  return data
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(() => initializeApollo(null, state), [state])
  return store
}
