const whittleStack = (fnName: string, error: Error): undefined | string => {
  if (!error?.stack) return error?.stack;

  const { name, message, stack } = error;

  const fnLineRegex = new RegExp(`\\n[^\\n]+${fnName}[^\\n]+`);
  const afterFnLine = stack?.split(fnLineRegex)[1];
  const untilLineRegex = new RegExp(
    `\\n[^\\n]+(anonymous|node_modules)[^\\n]+`
  );
  const beforeUntilLine = afterFnLine?.split(untilLineRegex)[0];

  return `${name}: ${message}${beforeUntilLine}`;
};

const errorToWhittledOneLine = (
  fnName: string,
  error: Error
): undefined | string => {
  if (!error?.stack) return error?.stack;

  const stack = whittleStack(fnName, error)?.replace(/\n/, "");

  return stack;
};

export const reportError = (
  error: string | Error,
  ...otherArgs: unknown[]
): Error => {
  const asError = error instanceof Error ? error : new Error(error);

  console.error(asError, ...otherArgs);

  return asError;
};
export default reportError;

export const warnError = (
  error: string | Error,
  ...otherArgs: unknown[]
): Error => {
  const asError = error instanceof Error ? error : new Error(error);
  asError.name = "Warning";

  console.warn(errorToWhittledOneLine("warnError", asError), ...otherArgs);

  return asError;
};

export const warnNull = (
  error: string | Error,
  ...otherArgs: unknown[]
): null => {
  const asError = error instanceof Error ? error : new Error(error);
  asError.name = "Warn/null";

  console.warn(errorToWhittledOneLine("warnNull", asError), ...otherArgs);

  return null;
};

export const debugNull = (
  error: string | Error,
  ...otherArgs: unknown[]
): null => {
  const asError = error instanceof Error ? error : new Error(error);
  asError.name = "Debug/null";

  console.debug(errorToWhittledOneLine("debugNull", asError), ...otherArgs);

  return null;
};
