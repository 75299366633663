import '../styles/reset.scss'
import '../styles/fonts.scss'
import '../styles/globals.scss'
import "../styles/dynamics-form.scss"
import "../styles/forms/forms.scss"
import "../styles/filters/filters.scss"
import 'react-sliding-pane/dist/react-sliding-pane.css'
import { RecoilRoot } from 'recoil'
import storeUTMData from 'utils/storeUTMData'
import { anchorJumpLinks } from 'utils/anchor-jump-links'
import { useApollo } from '../lib/withApollo'
import Router from 'next/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import '../styles/nprogress.scss'
import { AppProps } from 'next/dist/shared/lib/router/router'
import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

function AbcApp({ Component: PageComponent, pageProps }: AppProps): JSX.Element {
  const apolloClient = useApollo(pageProps)
  storeUTMData()
  anchorJumpLinks()
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GTM_ID) {
      TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID })
    }

  }, [])
  return (
    <RecoilRoot>
      <ApolloProvider client={apolloClient}>
        <PageComponent {...pageProps} />
      </ApolloProvider>
    </RecoilRoot>
  )
}

export default AbcApp
